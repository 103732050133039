<template>
<focus-trap :active="open">
  <div :class="[{'open':open},'SlideUp fixed left-0 right-0 bottom-0 h-content z-50 border bg-white shadow-lg']">
      <div class="absolute right-6 top-6 z-50">
        <button class="primary" @click.stop="Close">
          X
        </button>
      </div>
      <div class="pt-20 ">
        <component :is="content" />
      </div>
  </div>
</focus-trap>
</template>

<script>
import {onMounted} from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'SlideUp',
  data: () => {
    return {
    }
  },
  setup() {
    const store = useStore()
    const Close = () => {
      store.dispatch('ClearSlideUp')
    }
    const KeyUp = (evt) => {
      if (evt.keyCode === 27) {
          Close();
          removeEventListener('keyup', KeyUp, false);
      }
    }
    onMounted(()=>{
      document.addEventListener('keyup', KeyUp,false);
    })
    return {Close,KeyUp}
  },
  methods: {
  },
  computed: {
      content() {
          return this.$store.state.slideup_content
      },
      open() {
          return this.$store.state.slideup_open
      },
  }
}
</script>

<style scoped>
.SlideUp {
    height: 0;
    transition: all 0.15s ease-in-out;
    overflow: hidden;
}
.SlideUp.open {
  height: 100%;
}
</style>
