<template>
  <div class="fixed bottom-10 right-10">
      <button @click="ShowAddNote" aria-lable="Add A Note" class="primary">
          +
      </button>
  </div>
</template>

<script>
import AddNoteComp from './AddNote'
import {markRaw} from 'vue'
export default {
  name: 'Actions',
  setup() {
      let AddNote = markRaw(AddNoteComp)
      return {AddNote}
  },
  methods: {
      ShowAddNote() {
        this.$store.dispatch('SlideUp',{component:this.AddNote})
      },
  },
}
</script>

<style scoped>
</style>
