<template>
<div class="flex w-full flex-col px-12">
  <div class="flex flex-col my-6">
    <label for="Search">Search</label>
    <input id="Search" type="text" v-model="search_text" >
    <div v-if="loading.search" class="loading" >Searching</div>
  </div>
  <div v-if="search_results.length" class="w-full">
      <div  tabindex="0" role="button" @click="viewNote(note)" @keyup.enter.stop="viewNote(note)" v-for="(note,i) in search_results" :key="i" class="flex flex-col p-4 border my-2 mx-2 w-full shadow hover:shadow-lg focus:shadow-lg">
          <div class="note-value" v-html="note.content">
          </div>
          <p>
              <small>{{getDate(note.created_at)}}</small>
          </p>
      </div>
  </div>
  <div v-if="!$store.state.loading.notes && !search_results.length" class="w-full">
      <div  tabindex="0" role="button" @click="viewNote(note)" @keyup.enter.stop="viewNote(note)" v-for="(note,i) in notes" :key="i" class="flex flex-col p-4 border my-2 mx-2 w-full shadow hover:shadow-lg focus:shadow-lg">
          <div class="note-value" v-html="note.content">
          </div>
          <p>
              <small>{{getDate(note.created_at)}}</small>
          </p>
      </div>
  </div>
  <div v-if="$store.state.loading.notes" class="loading w-full flex justify-center my-12">Fetching Notes</div>
</div>
</template>

<script>
import moment from 'moment'
import {markRaw} from 'vue'
import NoteComp from './Note.vue'

export default {
  name: 'Notes',
  data: () => {
    return {
      search_text: '',
      search_timeout: false,
      search_results: [],
      loading: {
        search: false,
      },
    }
  },
  setup() {
      let Note = markRaw(NoteComp)
      return {Note}
  },
  watch: {
    search_text() {
      if(this.search_timeout) clearTimeout(this.search_timeout)
      this.search_timeout = setTimeout(this.Search,500)
    },
  },
  methods: {
    async Search() {
      this.loading.search = true
      let response = await fetch(`${this.$store.state.api}/search/?token=${this.$store.state.token}`,{
        method: "POST",
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.search_text),
      })
      this.search_results = await response.json()
      this.loading.search = false
    },
    viewNote(note) {
      this.$store.commit('note/SetState',{key:'note',value:note})
      this.$store.dispatch('SlideUp',{component:this.Note})
    },
    getDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm a')
    },
  },
  props: ['notes']
}
</script>

<style scoped>
.note-value {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
