<template>
  <div class="">
      <SlideDown />
      <Header />
      <Notes :notes="notes" />
      <Actions v-if="$store.state.user" />
      <SlideUp />
  </div>
</template>

<script>
import Header from '../components/Header'
import SlideDown from '../components/SlideDown'
import SlideUp from '../components/SlideUp'
import Actions from '../components/Actions'
import Notes from '../components/Notes'
import {onMounted} from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'Dashboard',
  components: {
      Header,
      SlideDown,
      SlideUp,
      Actions,
      Notes
  },
  setup() {
    const store = useStore()
    onMounted(async () => {
      if(store.state.user) store.dispatch("GetNotes")
    })
    document.addEventListener('note:added', (e) => {
      store.commit('AddNote',e.detail)
    })
  },
  computed: {
    notes() {
      return this.$store.state.notes
    },
  },
}
</script>

<style scoped>
</style>
