<template>
  <div class=" flex flex-col p-6">
    <div v-if="form.screen == 'login'" class=" flex flex-col p-6">
        <div class="flex flex-col items-center">
            <label for="email">Email Address</label>
            <input id="email" type="email" required v-model="form.email" >
        </div>
        <div class="flex flex-col items-center">
            <label for="password">Password</label>
            <input id="password" type="password" required v-model="form.password" >
        </div>
        <div class="flex my-6 justify-center">
            <button :disabled="loading" @click="SubmitForm" class="primary">
                Login
            </button>
        </div>
        <div class="flex my-6 justify-center">
            <button :disabled="loading" @click="form.screen = 'register'" class=" w-1/4">
                Register a new account
            </button>
        </div>
      </div>
    <div v-if="form.screen == 'register'" class=" flex flex-col p-6">
        <div class="flex flex-col items-center">
            <label for="name">Name</label>
            <input id="name" type="text" v-model="form.name" >
        </div>
        <div class="flex flex-col items-center">
            <label for="email">Email Address</label>
            <input id="email" type="email" required v-model="form.email" >
        </div>
        <div class="flex flex-col items-center">
            <label for="password">Password</label>
            <input id="password" type="password" required v-model="form.password" >
        </div>
        <div class="flex my-6 justify-center">
            <button :disabled="loading" @click="SubmitForm" class="primary">
                Register
            </button>
        </div>
        <div class="flex my-6 justify-center">
            <button :disabled="loading" @click="form.screen = 'login'" class=" w-1/4">
                Already have an account? Login here
            </button>
        </div>
      </div>
      <div class="flex my-6 justify-center">
          <p class="text-red" v-for="(error,i) in errors" :key="i" >{{error}}</p>
      </div>
      <div class="flex my-6 justify-center" role="status">
          <div v-if="loading" class="loading">Loading</div>
      </div>
  </div>
</template>

<script>
import md5 from 'md5'
export default {
  name: 'Login',
  data: () => {
      return {
          loading: false,
          form: {          
              screen: 'login',
              name: '',
              email: '',
              password: '',
          },
          errors: [],
      }
  },
  methods: {
      Validate() {
        let valid = true
        if(!this.form.email || !this.form.email.length) {
            valid = false
        }else{
            this.form.email = this.form.email.toLowerCase()
        }
        if(!this.form.password || !this.form.password.length) {
            valid = false
        }
        return valid
      },
      async SubmitForm() {
        this.errors = []
        this.loading = true
        if(!this.Validate()) {
            this.loading = false
            return
        }
        this.form.password = await md5(this.form.password)
        let response = await fetch(`${this.$store.state.api}/auth/`,{
            method: "POST",
            cache: 'no-cache',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.form),
        })
        response = await response.json()
        if(response && response.status == '1' && response.token) {
            this.$store.dispatch('Login',{user:response.user,token:response.token})
            this.$store.dispatch('ClearSlideDown')
            this.$store.dispatch("GetNotes")
        }else if(response && response.status == '2'){
            this.errors.push(response.error)
        }
        this.loading = false
      },
  },
  props: []
}
</script>

<style scoped>
</style>
