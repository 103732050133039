<template>
  <div class="note h-screen">
    <Editor
      initialValue=""
      :api-key="$store.state.tinyKey"
      v-model="note"
      :init="{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table'
        ],
        toolbar:
          'undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright | \
          bullist numlist outdent indent | help'
      }"
    />
    <div class="flex my-4 justify-end">
      <button @click="Save" class="primary" >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'Note',
  data: () => {
    return {
      
    }
  },
  methods: {
    async Save() {
      let response = await fetch(`${this.$store.state.api}/update/`,{
        method: "POST",
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({note:this.$store.state.note.note,token:this.$store.state.token}),
      })
      response = await response.json()
      if(response && response.status == '1') {
        this.note = ''
        // document.dispatchEvent(new CustomEvent('note:added', { detail: response.note }))
      }
    },
  },
  computed: {
    note: {
      get() {
        return this.$store.state.note.note.content
      },
      set(val) {
        this.$store.commit('note/UpdateNoteContent',val)
      },
    },
  },
  components: {
    Editor,
  },
}
</script>

<style scoped>
</style>
