<template>
  <div class="h-20 flex border-b py-4 px-2">
    <div class="w-1/2">
        <button v-if="!$store.state.user" @click="Login" class="primary">
            Login/Register
        </button>
        <button v-else @click="Logout" class="primary">
            Logout
        </button>
    </div>
    <div class="w-1/2 flex justify-end items-center">
        <p>{{$store.state.user.email}}</p>
    </div>
  </div>
</template>

<script>
import Login from '../components/Login'
import {markRaw} from 'vue'
export default {
  name: 'Header',
  setup() {
      let LoginComp = markRaw(Login)
      return {LoginComp}
  },
  methods: {
      Logout() {
        this.$store.commit('SetState',{key:'user',value:false})
        this.$store.state.cookies.delete('nat:token')
        location.reload()
      },
      Login() {
        this.$store.dispatch('SlideOut',{component:this.LoginComp})
      },
  },
  components: {
  },
}
</script>

<style scoped>
</style>
