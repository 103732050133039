<template>
  <div class="flex flex-col">
  <Editor
    initialValue=""
    :api-key="$store.state.tinyKey"
    v-model="note"
    :init="{
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap',
        'searchreplace visualblocks code fullscreen',
        'print preview anchor insertdatetime media',
        'paste code help wordcount table'
      ],
      toolbar:
        'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright | \
        bullist numlist outdent indent | help'
    }"
  />
  <div class="flex my-4 justify-end">
    <button @click="submit" class="primary" >
      Create
    </button>
  </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'AddNote',
  data: () => {
    return {
      note: ''
    }
  },
  methods: {
    async submit() {
      let response = await fetch(`${this.$store.state.api}/note/`,{
        method: "POST",
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({note:this.note,token:this.$store.state.token}),
      })
      response = await response.json()
      if(response && response.status == '1') {
        this.note = ''
        document.dispatchEvent(new CustomEvent('note:added', { detail: response.note }))
      }
    },
  },
  components: {
    Editor,
  },
}
</script>

<style scoped>
</style>
